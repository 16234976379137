<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> {{ $t('filter_report') }} </q-tooltip></q-btn
      >
      <q-btn
        outline
        color="primary"
        icon-right="archive"
        class="q-mr-sm"
        no-caps
        @click="exportTableCsv(prepareItemCSV(reports), prepareColumnCSV(columns), 'reports')"
        unelevated
      >
        <q-tooltip> {{ $t('export_csv') }} </q-tooltip>
      </q-btn>
    </q-toolbar>
    <q-intersection transition="slide-down" v-show="filter">
      <q-card flat v-if="filter" bordered class="q-mb-sm">
        <q-toolbar>
          <q-toolbar-title> {{ $t('filter') }} </q-toolbar-title>
          <q-btn flat round dense icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6">
            <q-select
              v-model="params.campaignId"
              use-input
              input-debounce="200"
              @filter="filterComboCampaigns"
              :options="campaignsFiltered"
              label="Campanha"
              option-label="name"
              option-value="id"
              outlined
            />
          </div>
          <div class="col-6">
            <date-input
              buttons
              outlined
              label="Data dos criativos"
              ref="date"
              :v-model="params.date"
              @update="(value) => (params.date = value)"
              :rules="[(val) => val !== '' || 'O campo data dos criativos é obrigatório']"
              clearable
              range
            />
          </div>
          <div class="col-12 text-right">
            <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterReports" />
          </div>
        </q-card-section>
      </q-card>
    </q-intersection>
  </div>
</template>

<script>
import ReportMixin from '../../../mixins/ReportMixin';
import DateInput from '../../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'AdvertiserHeaderCreativeReports',
  components: { DateInput },
  mixins: [ReportMixin],
  data() {
    return {
      filter: true,
      params: {
        campaignId: null,
        date: ''
      },
      campaignsList: [],
      campaignsFiltered: []
    };
  },

  created() {
    this.getCampaigns();
  },

  methods: {
    async getCampaigns() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=advertiser&path=/api/get/campaign/advertiser/' + this.user.advertiser_id
        );
        if (status === 200) {
          this.campaignsList = data;
          this.campaignsFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterComboCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignsFiltered = this.campaignsList;
        this.campaignsFiltered = this.campaignsList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    clearFilter() {
      this.params = {
        campaignId: null,
        date: ''
      };
      this.$refs.campaign.value = null;
      this.$refs.date.value = null;
      this.ActionSetFilterReports();
    },

    onFilterReports() {
      if (this.params.date == '') {
        this.errorNotify('É necessário escolher uma data!');
        return;
      }
      let filterSelect = [];

      filterSelect.push(`id_advertiser=${this.user.advertiser_id}`);

      if (this.params.campaignId) filterSelect.push(`id_campaign=${this.params.campaignId.id}`);
      if (this.params.date) {
        if (this.params.date.split(' ').length == 3) {
          let [from, , to] = this.params.date.split(' ');
          (from = this.$moment(this.stringToDate(from, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD')),
            (to = this.$moment(this.stringToDate(to, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD'));

          filterSelect.push(`start_date=${from}`);
          filterSelect.push(`end_date=${to}`);
        }
      }

      this.ActionSetFilterReports(filterSelect.join('%26'));
      this.getCreativeReportAdvertiser();
    }
  }
};
</script>
