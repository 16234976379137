<template>
  <q-layout>
    <q-page padding>
      <q-toolbar class="q-pr-none">
        <q-toolbar-title class="text-h5">{{ $t('creatives_report') }}</q-toolbar-title>
        <q-breadcrumbs>
          <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
          <q-breadcrumbs-el :label="$t('creatives')" exact />
        </q-breadcrumbs>
      </q-toolbar>
      <q-separator spaced />
      <advertiser-header-creative-reports></advertiser-header-creative-reports>
      <advertiser-list-creative-reports></advertiser-list-creative-reports>
    </q-page>
  </q-layout>
</template>

<script>
import AdvertiserHeaderCreativeReports from '../../components/reports/advertiser/AdvertiserHeaderCreativeReports.vue';
import AdvertiserListCreativeReports from '../../components/reports/advertiser/AdvertiserListCreativeReports.vue';
import ReportMixin from '../../mixins/ReportMixin';

export default {
  name: 'AdvertiserCreativeReports',
  components: {
    AdvertiserHeaderCreativeReports,
    AdvertiserListCreativeReports
  },
  mixins: [ReportMixin]
};
</script>
